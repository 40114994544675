<template>
  <b-card>
    <b-table
      ref="table"
      :items="getReports"
      :fields="fields"
      :current-page="pagination.currentPage"
      :per-page="pagination.perPage"
      responsive
      empty-text=""
      show-empty
    >
      <template #cell(actions)="{item}">
        <b-dropdown
          variant="link"
          no-caret
        >
          <template #button-content>
            <feather-icon
              icon="MoreVerticalIcon"
              size="16"
              class="align-middle text-body"
            />
          </template>

          <b-dropdown-item
            @click="toggleStatus(item)"
          >
            <feather-icon
              :icon="item.readAt ? 'EyeOffIcon' :'EyeIcon'"
            />
            {{ item.readAt ? 'Mark as unread' :'Mark as read' }}
          </b-dropdown-item>
        </b-dropdown>
      </template>
    </b-table>
  </b-card>
</template>
<script>
import paginationData from '@/common/compositions/common/paginationData'
import handleAlerts from '@/common/compositions/common/handleAlerts'

export default {
  name: 'FeedbackReports',
  computed: {
    fields() {
      return [
        'id',
        { key: 'content', label: 'message' },
        { key: 'user.profile', formatter: v => `${v.first_name} ${v.last_name}`, label: 'username' },
        { key: 'user.email', label: 'email' },
        'sentAt',
        { key: 'readAt', label: 'Status', formatter: v => (v ? 'Read' : 'Unread') },
        'actions',
      ]
    },
  },
  setup() {
    const { successfulOperationAlert } = handleAlerts()
    const { pagination } = paginationData()
    return { successfulOperationAlert, pagination }
  },
  methods: {
    getReports() {
      return this.$activities.get(`/internalops/announcement/${this.$route.params.id}/report`).then(res => {
        this.pagination.totalRows = res.data.pagination.total
        return res.data.data || []
      })
    },
    toggleStatus(item) {
      this.$activities.post(`/internalops/announcement/${this.$route.params.id}/report/${item.id}`).then(() => {
        const operationType = item.readAt ? 'set as unread' : 'set as read'
        item.readAt = item.readAt ? null : true
        this.successfulOperationAlert(`Report is ${operationType} successfully`)
      })
    },
  },
}
</script>
<style lang="">

</style>
